import React, { memo } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {
	BorderLinearProgress,
	DepositCardContent,
	DepositCardRoot,
	DepositTitle,
	DetailLink
} from './styles/DepositCard.styles';

interface DepositCardProps {
	title: string;
	currentAmount?: number;
	initialAmount?: number;
	progressColor?: string;
	detailUrl: string;
}

const DepositCard: React.FC<DepositCardProps> = props => {
	const {
		title,
		currentAmount = 0,
		initialAmount = 0,
		detailUrl,
		progressColor = ''
	} = props;

	const currentPercentage = Math.round((currentAmount / initialAmount) * 100);

	const windowAmount = currentAmount - initialAmount;
	const windowPercentage = Math.round((windowAmount / initialAmount) * 100);

	const progressBarValue = currentPercentage > 100 ? 100 : currentPercentage;

	const renderedPercentage =
		!!windowPercentage &&
		` (${windowPercentage > 0 ? '+' : ''}${windowPercentage}%)`;

	return (
		<DepositCardRoot>
			<BorderLinearProgress
				progressColor={progressColor}
				value={progressBarValue}
				variant="determinate"
			/>
			<DepositTitle component="h2" variant="body1">
				{title} {renderedPercentage}
			</DepositTitle>
			<DepositCardContent>
				<Typography variant="h4">
					Rp{' '}
					<NumberFormat
						decimalSeparator=""
						displayType="text"
						thousandSeparator="."
						value={currentAmount}
					/>
				</Typography>
				<DetailLink
					component={Link}
					to={detailUrl}
					underline="none"
					variant="body1"
				>
					Lihat Detail
				</DetailLink>
			</DepositCardContent>
		</DepositCardRoot>
	);
};

export default memo(DepositCard);
