import { Box, IconButton, styled } from '@mui/material';

export const DialogCloseButtonRoot = styled(Box)(({ theme }) => ({
	padding: '24px 24px 8px',
	display: 'flex',
	justifyContent: 'flex-end',
	[theme.breakpoints.down('sm')]: {
		padding: '16px 16px 8px'
	}
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
	color: theme.palette.grey[600],
	height: 44,
	width: 44,
	[theme.breakpoints.down('sm')]: {
		height: 40,
		width: 40
	},
	'& .MuiSvgIcon-root': {
		height: 24,
		width: 24
	}
}));
