import { DialogContent as MuiDialogContent, styled } from '@mui/material';

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
	padding: '0 24px 24px',
	[theme.breakpoints.down('sm')]: {
		padding: '0 16px 16px'
	}
}));

export default DialogContent;
