import { Box, Divider, styled, Typography } from '@mui/material';
import { BrandIcon } from 'components/Icons';
import { SimpleImg } from 'react-simple-img';
import LoginGoogle from '../LoginGoogle';

export const LoginRoot = styled(Box)(({ theme }) => ({
	height: '100%',
	minHeight: 640,
	display: 'flex',
	[theme.breakpoints.down('md')]: {
		display: 'block',
		minHeight: 0
	}
}));

export const OrnamentContainer = styled(Box)(({ theme }) => ({
	flex: '0 1 404px',
	backgroundColor: theme.palette.green[50],
	position: 'relative',
	overflow: 'hidden',
	[theme.breakpoints.down('md')]: {
		maxHeight: 204,
		height: '100%'
	},
	[theme.breakpoints.down('sm')]: {
		maxHeight: 152
	}
}));

export const StyledBrandIcon = styled(BrandIcon)(({ theme }) => ({
	height: 32,
	width: 182,
	marginTop: 40,
	marginLeft: 40,
	color: theme.palette.primary.main,
	[theme.breakpoints.down('md')]: {
		marginTop: 24,
		marginLeft: 24
	},
	[theme.breakpoints.down('sm')]: {
		height: 20,
		width: 102,
		marginTop: 12,
		marginLeft: 16
	}
}));

export const AccessAccountContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '55%',
	margin: 24,
	[theme.breakpoints.down('md')]: {
		position: 'absolute',
		right: 64,
		top: 44,
		height: 'auto',
		justifyContent: 'flex-end',
		margin: 0
	},
	[theme.breakpoints.down('sm')]: {
		right: 16,
		top: 28
	}
}));

export const StyledAccessAccount = styled(SimpleImg)(({ theme }) => ({
	height: '278px !important',
	maxWidth: '275px !important',
	width: '100%',
	[theme.breakpoints.down('md')]: {
		height: '174px !important',
		maxWidth: '173px !important'
	},
	[theme.breakpoints.down('sm')]: {
		height: '136px !important',
		maxWidth: '134px !important'
	}
}));

export const OrnamentWaveContainer = styled(Box)(({ theme }) => ({
	position: 'absolute',
	bottom: -5,
	right: 0,
	left: 0,
	[theme.breakpoints.down('md')]: {
		bottom: -24,
		right: 0,
		left: -24
	},
	[theme.breakpoints.down('sm')]: {
		bottom: 0,
		right: 0,
		left: -52
	}
}));

export const StyledOrnamentWave = styled(SimpleImg)(({ theme }) => ({
	maxHeight: '268px !important',
	maxWidth: '403px !important',
	height: '100% !important',
	width: '100%',
	[theme.breakpoints.down('md')]: {
		height: '152px !important',
		maxWidth: '274px !important',
		transform: 'scaleX(-1)'
	},
	[theme.breakpoints.down('sm')]: {
		height: '94px !important',
		maxWidth: '194 !important'
	}
}));

export const InputContainer = styled(Box)({
	flex: '1 1 876px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
});

export const InputWrapper = styled(Box)(({ theme }) => ({
	maxWidth: 504,
	width: '100%',
	margin: `0 16px 24px`,
	[theme.breakpoints.down('md')]: {
		margin: `88px 24px`
	},
	[theme.breakpoints.down('sm')]: {
		margin: `40px 16px`
	}
}));

export const StyledHeading = styled(Typography)(({ theme }) => ({
	marginBottom: 8,
	[theme.breakpoints.down('sm')]: {
		marginBottom: 4
	}
})) as typeof Typography;

export const StyledSubHeading = styled(Typography)(({ theme }) => ({
	marginBottom: 48,
	color: theme.palette.grey[700],
	display: 'block',
	[theme.breakpoints.down('sm')]: {
		marginBottom: 32
	}
})) as typeof Typography;

export const StyledSignupText = styled(Typography)(({ theme }) => ({
	fontWeight: theme.typography.fontWeightBold,
	color: theme.palette.primary.main
})) as typeof Typography;

export const StyledGoogleLogin = styled(LoginGoogle)(({ theme }) => ({
	marginBottom: 16,
	[theme.breakpoints.down('sm')]: {
		marginBottom: 12
	}
}));

export const DividerContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: '100%',
	justifyContent: 'center',
	alignItems: 'center',
	marginBottom: 16,
	[theme.breakpoints.down('sm')]: {
		marginBottom: 12
	}
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
	flex: `1 1 50%`,
	backgroundColor: theme.palette.grey[300]
}));

export const DividerLabel = styled(Typography)(({ theme }) => ({
	marginLeft: 24,
	marginRight: 24,
	color: theme.palette.grey[400],
	[theme.breakpoints.down('sm')]: {
		marginLeft: 16,
		marginRight: 16
	}
})) as typeof Typography;
