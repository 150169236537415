import { alpha, styled, TextField } from '@mui/material';

export const StyledTextField = styled(TextField)(({ theme }) => ({
	'& .MuiFilledInput-root': {
		backgroundColor: 'transparent',
		marginTop: `0 !important`,
		border: `1px solid ${theme.palette.grey[600]}`,
		overflow: 'hidden',
		borderRadius: 8,
		lineHeight: 1.5,
		padding: `23px 16px 7px`,
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		[theme.breakpoints.down('sm')]: {
			padding: `18px 12px 7px`,
			borderRadius: 4
		},
		'&:hover': {
			backgroundColor: 'transparent'
		},
		'&.Mui-focused': {
			backgroundColor: 'transparent',
			boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
			borderColor: theme.palette.primary.main
		},
		'&.Mui-error': {
			boxShadow: `${alpha(theme.palette.error.main, 0.5)} 0 0 4px`,
			borderColor: theme.palette.error.main
		},
		'&.Mui-disabled': {
			opacity: 0.4
		},
		'& .MuiFilledInput-input': {
			padding: 0,
			height: 'unset'
		}
	},
	'& .MuiInputLabel-root': {
		color: theme.palette.grey[600],
		top: 0,
		left: 0,
		transform: `translate(16px, 17px)`,
		[theme.breakpoints.down('sm')]: {
			transform: `translate(12px, 14px)`
		},
		'&.MuiInputLabel-shrink': {
			transform: `translate(16px, 8px) scale(0.75)`,
			[theme.breakpoints.down('sm')]: {
				transform: `translate(12px, 6px) scale(0.7143)`
			}
		},
		'&.Mui-focused': {
			color: theme.palette.primary.main
		},
		'&.Mui-error': {
			color: theme.palette.error.main
		},
		'&.Mui-disabled': {
			opacity: 0.4
		}
	},
	'& .MuiFormHelperText-root': {
		color: theme.palette.grey[600],
		position: 'absolute',
		marginTop: 4,
		marginLeft: 16,
		top: '100%',
		letterSpacing: 'inherit',
		[theme.breakpoints.down('sm')]: {
			marginTop: 2,
			marginLeft: 12
		},
		'&': {
			...theme.typography.body2
		},
		'&.Mui-error': {
			color: theme.palette.error.main
		},
		'&.Mui-disabled': {
			opacity: 0.4
		}
	}
}));
