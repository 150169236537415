import { MenuItem } from 'constants/menu-options';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
	LeftContainer,
	MenuIconButton,
	StyledAppBar,
	StyledBrandIcon,
	StyledBrandIconButton,
	StyledMenuIcon,
	StyledToolbar,
	VoidBox
} from './styles/TopNavigation.styles';
import TopNavigationDrawer from './TopNavigationDrawer';

interface TopNavigationProps {
	homeUrl: string;
	menuOptions: MenuItem[];
}

const TopNavigation: React.FC<TopNavigationProps> = props => {
	const { homeUrl, menuOptions } = props;

	const [open, setOpen] = useState(false);

	const handleOpenDrawer = () => {
		setOpen(true);
	};

	const handleCloseDrawer = () => {
		setOpen(false);
	};

	return (
		<>
			<TopNavigationDrawer
				homeUrl={homeUrl}
				menuOptions={menuOptions}
				open={open}
				onClose={handleCloseDrawer}
				onOpen={handleOpenDrawer}
			/>
			<StyledAppBar elevation={0}>
				<StyledToolbar>
					<LeftContainer>
						<MenuIconButton
							aria-describedby="Open drawer"
							onClick={handleOpenDrawer}
						>
							<StyledMenuIcon />
						</MenuIconButton>
						<StyledBrandIconButton component={Link} to={homeUrl}>
							<StyledBrandIcon />
						</StyledBrandIconButton>
					</LeftContainer>
				</StyledToolbar>
			</StyledAppBar>
			<VoidBox />
		</>
	);
};

export default TopNavigation;
