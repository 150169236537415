import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { VAULT_API_V2 } from 'constants/api';
import { RootState } from 'store';
import {
	AuthUserResponse,
	CustomBaseQuery,
	ForgotPasswordArgs,
	GoogleLoginArgs,
	LoginArgs,
	LoginResponse
} from 'store/types';

export const authApi = createApi({
	reducerPath: 'authApi',
	baseQuery: fetchBaseQuery({
		baseUrl: VAULT_API_V2,
		prepareHeaders: (headers, { getState }) => {
			const { token } = (getState() as RootState).auth;

			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}

			return headers;
		}
	}) as CustomBaseQuery,
	endpoints: builder => ({
		login: builder.mutation<LoginResponse, LoginArgs>({
			query: ({ email, password }) => ({
				url: 'login',
				method: 'POST',
				body: {
					email,
					password
				}
			})
		}),
		googleLogin: builder.mutation<LoginResponse, GoogleLoginArgs>({
			query: ({ idToken }) => ({
				url: 'google_auth',
				method: 'POST',
				body: {
					idToken,
					isRegister: false
				}
			})
		}),
		authUser: builder.query<AuthUserResponse, unknown>({
			query: () => ({ url: 'users/current' })
		}),
		forgotPassword: builder.mutation<unknown, ForgotPasswordArgs>({
			query: ({ email }) => ({
				url: 'forgot',
				method: 'POST',
				body: {
					email
				}
			})
		})
	})
});

export const {
	useLoginMutation,
	useGoogleLoginMutation,
	useAuthUserQuery,
	useForgotPasswordMutation
} = authApi;
