import { alpha, createTheme } from '@mui/material';

const defaultTheme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 905,
			lg: 1240,
			xl: 1440
		}
	},
	palette: {
		primary: {
			main: '#2FA67F',
			light: '#F0FAF7',
			contrastText: '#FFF'
		},
		secondary: {
			main: '#F5F5F5',
			contrastText: '#2FA67F'
		},
		error: {
			main: '#CC4644',
			contrastText: '#FFF',
			light: alpha('#CC4644', 0.2)
		},
		warning: {
			main: '#FFCE6D',
			contrastText: '#333'
		},
		background: {
			paper: '#FFF',
			default: '#FFF'
		},
		text: {
			primary: '#333333',
			secondary: '#858585'
		},
		grey: {
			50: '#F7F7F7',
			100: '#EEEEEE',
			200: '#E3E3E3',
			300: '#D1D1D1',
			400: '#ACACAC',
			500: '#888888',
			600: '#646464',
			700: '#515151'
		},
		green: {
			50: '#E0F2EC',
			100: '#B3DFCF',
			200: '#82CBB1',
			300: '#52B693',
			400: '#2FA67E',
			500: '#12966B',
			600: '#0E8960',
			700: '#077952',
			800: '#006944',
			900: '#004D2B'
		},
		success: {
			contrastText: '#33333',
			dark: '#388e3c',
			light: '#F8FEFC',
			main: '#2FA67F'
		},
		common: {
			black: '#333333',
			white: '#FFFFFF',
			blue: '#3D7DDE',
			orange: '#FF9C41'
		}
	}
});

const {
	breakpoints,
	typography: { pxToRem, fontWeightBold, fontWeightRegular }
} = defaultTheme;

const theme = createTheme(defaultTheme, {
	typography: {
		h1: {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: fontWeightBold,
			fontSize: 39,
			lineHeight: 1.5,
			[breakpoints.down('sm')]: {
				fontSize: 34
			}
		},
		h2: {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: fontWeightBold,
			fontSize: 31,
			lineHeight: 1.5,
			[breakpoints.down('sm')]: {
				fontSize: 27
			}
		},
		h3: {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: fontWeightBold,
			fontSize: 25,
			lineHeight: 1.5,
			[breakpoints.down('sm')]: {
				fontSize: 22
			}
		},
		h4: {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: fontWeightBold,
			fontSize: 20,
			lineHeight: 1.5,
			[breakpoints.down('sm')]: {
				fontSize: 18
			}
		},
		h5: {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: fontWeightBold,
			fontSize: 20,
			lineHeight: 1.5,
			[breakpoints.down('sm')]: {
				fontSize: 14
			}
		},
		body1: {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: fontWeightRegular,
			fontSize: 16,
			lineHeight: 1.5,
			[breakpoints.down('sm')]: {
				fontSize: 14
			}
		},
		body2: {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: fontWeightRegular,
			fontSize: 13,
			lineHeight: 1.5,
			[breakpoints.down('sm')]: {
				fontSize: 11
			}
		},
		button: {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: fontWeightBold,
			fontSize: pxToRem(16),
			lineHeight: 1.5,
			[breakpoints.down('sm')]: {
				fontSize: pxToRem(14)
			}
		}
	},
	layout: {
		grid: {
			display: 'grid',
			gridTemplateColumns: 'repeat(12,1fr)',
			columnGap: 20,
			[breakpoints.down('sm')]: {
				gridTemplateColumns: 'repeat(8,1fr)'
			},
			[breakpoints.down('xs')]: {
				gridTemplateColumns: 'repeat(4,1fr)'
			}
		},
		container: {
			margin: '0 auto',
			maxWidth: 1104,
			padding: `0 32px`,
			[breakpoints.down('xl')]: {
				margin: `0 200px`
			},
			[breakpoints.down('lg')]: {
				margin: `0 auto`,
				maxWidth: 904,
				padding: `0 32px`
			},
			[breakpoints.down('md')]: {
				margin: `0 32px`
			},
			[breakpoints.down('sm')]: {
				margin: `0 16px`
			}
		},
		dashboardContainer: {
			margin: '0 auto',
			maxWidth: 1104,
			padding: `0 32px`,
			[breakpoints.down('lg')]: {
				margin: `0 auto`,
				maxWidth: 904,
				padding: `0 32px`
			},
			[breakpoints.down('md')]: {
				margin: `0 32px`,
				padding: 0
			},
			[breakpoints.down('sm')]: {
				margin: `0 16px`
			}
		}
	},
	components: {
		MuiIconButton: {
			styleOverrides: {
				sizeMedium: {
					padding: 10,
					[breakpoints.down('sm')]: {
						padding: 8
					}
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'capitalize'
				}
			}
		}
	}
});

console.log(theme);

export default theme;
