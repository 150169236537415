import { Box, ButtonBase } from '@mui/material';
import React, { memo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmail, isRequiredString } from 'constants/yup-validation';
import { useLoginMutation } from 'store/services';
import { useTranslate } from 'hooks';
import { Button } from 'components/Button';
import { RequestErrorReturn } from 'store/types';
import {
	EmailField,
	ForgotButton,
	ForgotButtonContainer,
	PasswordField
} from './styles/LoginForm.styles';

const pageContent = {
	en: {
		emailLabel: 'Email',
		passwordLabel: 'Password',
		forgotPassword: 'Forgot Password?',
		loginButtonLabel: 'Login'
	},
	id: {
		emailLabel: 'Email',
		passwordLabel: 'Kata Sandi',
		forgotPassword: 'Lupa Kata Sandi?',
		loginButtonLabel: 'Masuk'
	}
};

const schema = yup.object().shape({
	email: isEmail.concat(isRequiredString),
	password: isRequiredString
});

interface Inputs {
	email: string;
	password: string;
}

interface LoginFormProps {
	onLoginSuccess: () => void;
	onLoginError: (error: RequestErrorReturn) => void;
	onForgotButtonClicked: () => void;
}

const LoginForm: React.FC<LoginFormProps> = props => {
	const { onLoginSuccess, onLoginError, onForgotButtonClicked } = props;

	const { language, parseValidationMessage } = useTranslate();

	const { handleSubmit, control } = useForm<Inputs>({
		resolver: yupResolver(schema)
	});

	const [login, { isLoading }] = useLoginMutation();

	const onSubmit = handleSubmit(async data => {
		try {
			await login(data).unwrap();
			onLoginSuccess();
		} catch (error) {
			onLoginError(error as RequestErrorReturn);
		}
	});

	const content = pageContent[language];

	return (
		<Box component="form" onSubmit={onSubmit}>
			<Controller
				control={control}
				defaultValue=""
				name="email"
				render={({ field, fieldState: { error } }) => (
					<EmailField
						error={!!error?.message}
						fullWidth
						helperText={parseValidationMessage(error)}
						id="email"
						label={content.emailLabel}
						type="email"
						{...field}
					/>
				)}
			/>

			<Controller
				control={control}
				defaultValue=""
				name="password"
				render={({ field, fieldState: { error } }) => (
					<PasswordField
						error={!!error?.message}
						fullWidth
						helperText={parseValidationMessage(error)}
						id="password"
						label={content.passwordLabel}
						type="password"
						{...field}
					/>
				)}
			/>

			<ForgotButtonContainer>
				<ButtonBase disableRipple onClick={onForgotButtonClicked}>
					<ForgotButton component="span" variant="body1">
						{content.forgotPassword}
					</ForgotButton>
				</ButtonBase>
			</ForgotButtonContainer>

			<Button
				color="primary"
				fullWidth
				loading={isLoading}
				type="submit"
				variant="contained"
			>
				{content.loginButtonLabel}
			</Button>
		</Box>
	);
};

export default memo(LoginForm);
