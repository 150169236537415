import React, { useCallback, useState } from 'react';
import { Snackbar } from '@mui/material';
import { Alert } from 'components/Alert';

const useToast = () => {
	const [toastMessage, setToastMessage] = useState('');
	const [open, setOpen] = useState('');

	const showToast = (
		message: string,
		variant: 'success' | 'error' = 'error'
	) => {
		setToastMessage(message);
		setOpen(variant);
	};

	const resetToast = () => {
		setOpen('');
	};

	const renderToast = useCallback(
		() => (
			<>
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					autoHideDuration={6000}
					open={open === 'success'}
					onClose={resetToast}
				>
					<Alert
						elevation={3}
						severity="success"
						sx={{ width: '100%' }}
						variant="filled"
						onClose={resetToast}
					>
						{toastMessage}
					</Alert>
				</Snackbar>
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					autoHideDuration={6000}
					open={open === 'error'}
					onClose={resetToast}
				>
					<Alert
						elevation={3}
						severity="error"
						sx={{ width: '100%' }}
						variant="filled"
						onClose={resetToast}
					>
						{toastMessage}
					</Alert>
				</Snackbar>
			</>
		),
		[open, toastMessage]
	);

	return { open, toastMessage, renderToast, showToast, resetToast };
};

export default useToast;
