import { Hidden } from '@mui/material';
import { SideNavigation, TopNavigation } from 'components/Navigation';
import { menuOptions } from 'constants/menu-options';
import { useTranslate } from 'hooks';
import React from 'react';
import { Outlet } from 'react-router-dom';
import {
	DashboardContentContainer,
	DashboardRoot
} from './styles.tsx/Dashboard.styles';

const Dashboard: React.FC = () => {
	const { language } = useTranslate();

	const option = menuOptions[language];

	return (
		<DashboardRoot>
			<Hidden lgUp>
				<TopNavigation homeUrl="/dashboard/overview" menuOptions={option} />
			</Hidden>
			<Hidden lgDown>
				<SideNavigation homeUrl="/dashboard/overview" menuOptions={option} />
			</Hidden>
			<DashboardContentContainer>
				<Outlet />
			</DashboardContentContainer>
		</DashboardRoot>
	);
};

export default Dashboard;
