import {
	DialogContentText as MuiDialogContentText,
	styled
} from '@mui/material';

const DialogContentText = styled(MuiDialogContentText)(({ theme }) => ({
	color: theme.palette.grey[700],
	marginBottom: 16
}));

export default DialogContentText;

// const DialogContentText = withStyles(({ typography, palette, breakpoints }) => ({
// 	root: {
// 		color: palette.black[80],
// 		marginBottom: typography.pxToRem(24)
// 	}
// }))(DialogContentTextComponent);

// export default DialogContentText;
