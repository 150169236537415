import { Box, styled, Typography } from "@mui/material";
import { TextField } from "components/Input";

export const EmailField = styled(TextField)(({ theme }) => ({
	marginBottom: `38px !important`,
	[theme.breakpoints.down('sm')]: {
		marginBottom: `30px !important`
	}
}));

export const PasswordField = styled(TextField)(({ theme }) => ({
	marginBottom: `24px !important`,
	[theme.breakpoints.down('sm')]: {
		marginBottom: `20px !important`
	}
}));

export const ForgotButtonContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'flex-end',
	marginBottom: 48,
	[theme.breakpoints.down('sm')]: {
		marginBottom: 32
	}
}));

export const ForgotButton = styled(Typography)(({ theme }) => ({
	color: theme.palette.common.blue,
	fontWeight: theme.typography.fontWeightBold
})) as typeof Typography;

