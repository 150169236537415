import React from 'react';
import { LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab';
import {
	StyledLoadingButton,
	StyledLoadingButtonProps
} from './styles/Button.styles';

interface ButtonProps extends Omit<MuiLoadingButtonProps, 'color' | 'variant'> {
	color?: StyledLoadingButtonProps['btnColor'];
	variant?: StyledLoadingButtonProps['btnVariant'];
}

const Button: React.FC<ButtonProps> = props => {
	const {
		color = 'standard',
		variant = 'contained',
		children,
		...otherProps
	} = props;
	return (
		<StyledLoadingButton btnColor={color} btnVariant={variant} {...otherProps}>
			{children}
		</StyledLoadingButton>
	);
};

export default Button;
