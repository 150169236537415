import * as yup from 'yup';

export const isString = yup.string();
export const isMixed = yup.mixed();
export const isNumber = yup.number();
export const isBool = yup.bool();
export const isObject = yup
	.mixed()
	.test('empty-object', 'fieldRequired', val =>
		val ? typeof val === 'object' : true
	);
export const isRequiredString = yup.string().required('fieldRequired');
export const isRequiredMixed = yup.mixed().required('fieldRequired');
export const isRequiredNumber = yup
	.number()
	.typeError('fieldRequired')
	.required('fieldRequired');
export const isRequiredObject = yup
	.mixed()
	.test('empty-object', 'fieldRequired', val => typeof val === 'object')
	.required('fieldRequired');

export const isEmail = yup.string().email('invalidEmailFormat');
