import { Box, Drawer, ListItemButton, styled } from '@mui/material';
import { BrandIcon } from 'components/Icons';

export const StyledDrawer = styled(Drawer)({
	width: 272,
	flexShrink: 0,
	'& .MuiDrawer-paper': {
		width: 272,
		border: 'none',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	}
});

export const BrandIconContainer = styled(Box)({
	padding: 24,
	marginBottom: 16
});

export const StyledBrandIcon = styled(BrandIcon)(({ theme }) => ({
	width: 165,
	height: 33,
	color: theme.palette.primary.main
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
	padding: '20px 24px',
	color: theme.palette.grey[600],
	'&.Mui-selected': {
		color: theme.palette.primary.main,
		fontWeight: theme.typography.fontWeightBold
	},
	'&.Mui-selected::before': {
		width: '100%'
	},
	'&.Mui-selected::after': {
		content: '""',
		width: 4,
		position: 'absolute',
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.primary.main
	}
})) as typeof ListItemButton;

export const StyledListItemLogoutButton = styled(ListItemButton)(
	({ theme }) => ({
		padding: '20px 24px',
		color: theme.palette.error.main,
		fontWeight: theme.typography.fontWeightBold
	})
);

export const LanguageSwitcherContainer = styled(Box)({
	padding: 24
});
