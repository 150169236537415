import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { RootState } from '../store';

type Language = 'id' | 'en';

interface SettingsState {
	language: Language;
}

const initialState: SettingsState = {
	language: (Cookies.get('language') as Language) || 'id'
};

export const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setLanguage: (state, action: PayloadAction<Language>) => {
			Cookies.set('language', action.payload, {
				expires: 365
			});
			state.language = action.payload;
		}
	}
});

export const { setLanguage } = settingsSlice.actions;

const selectSettings = (state: RootState) => state.settings;

export const selectCurrentLanguage = createSelector(
	selectSettings,
	settings => settings.language
);

export const settingsReducer = settingsSlice.reducer;
