import {
	Box,
	IconButton,
	lighten,
	List,
	ListItemButton,
	styled,
	SwipeableDrawer
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BrandIcon } from 'components/Icons';
import { LanguageSwitcher } from 'components/Switch';

export const StyledSwipeableDrawer = styled(SwipeableDrawer)({
	width: `74%`,
	maxWidth: 272,
	overflow: 'visible',
	'& .MuiDrawer-paper': {
		width: `74%`,
		maxWidth: 272,
		overflow: 'visible'
	}
});

export const StyledCloseIconButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	right: -64,
	top: 12,
	height: 44,
	width: 44,
	color: theme.palette.grey[500],
	backgroundColor: theme.palette.common.white,
	[theme.breakpoints.down('sm')]: {
		height: 40,
		width: 40
	},
	'&:hover': {
		backgroundColor: lighten(theme.palette.grey[500], 0.7)
	}
}));

export const StyledCloseIcon = styled(CloseIcon)({
	height: 24,
	width: 24
});

export const StyledList = styled(List)(({ theme }) => ({
	paddingLeft: 16,
	paddingRight: 26,
	[theme.breakpoints.down('sm')]: {
		paddingRight: 16
	}
})) as typeof List;

export const BrandIconContainer = styled(Box)(({ theme }) => ({
	paddingTop: 18,
	paddingBottom: 18,
	marginBottom: 12,
	[theme.breakpoints.down('sm')]: {
		paddingTop: 32,
		paddingBottom: 12,
		marginBottom: 0
	}
}));

export const StyledBrandIcon = styled(BrandIcon)(({ theme }) => ({
	width: 127,
	height: 25,
	color: theme.palette.primary.main,
	[theme.breakpoints.down('sm')]: {
		width: 114,
		height: 20
	}
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
	padding: 12,
	fontWeight: theme.typography.fontWeightBold
})) as typeof ListItemButton;

export const StyledListItemLogoutButton = styled(StyledListItemButton)(
	({ theme }) => ({
		borderTop: `1px solid ${theme.palette.grey[100]}`,
		color: theme.palette.error.main
	})
);

export const StyledLanguageSwitcher = styled(LanguageSwitcher)({
	marginTop: 48,
	marginLeft: 16,
	marginBottom: 48
});
