import { styled, Tab, Tabs } from '@mui/material';

export const StyledTabs = styled(Tabs)({
	padding: 2,
	'& .MuiTabs-indicator': {
		display: 'none'
	}
});

export const StyledTab = styled(Tab)(({ theme }) => ({
	opacity: 1,
	width: 44,
	minWidth: 'unset',
	minHeight: 29,
	padding: 0,
	color: theme.palette.primary.main,
	'&:first-of-type': {
		borderTopLeftRadius: 4,
		borderBottomLeftRadius: 4
	},
	'&:last-child': {
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4
	},
	'&.Mui-selected': {
		color: theme.palette.common.white,
		backgroundColor: theme.palette.primary.main
	}
}));
