import React, { useCallback, useState } from 'react';
import AccessAccount from 'assets/access-account.svg';
import OrnamentWave from 'assets/ornament-wave.svg';
import { useToast, useTranslate } from 'hooks';
import { RequestErrorReturn } from 'store/types';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginForm from './LoginForm';
import {
	AccessAccountContainer,
	DividerContainer,
	DividerLabel,
	InputContainer,
	InputWrapper,
	LoginRoot,
	OrnamentContainer,
	OrnamentWaveContainer,
	StyledAccessAccount,
	StyledBrandIcon,
	StyledDivider,
	StyledGoogleLogin,
	StyledHeading,
	StyledOrnamentWave,
	StyledSignupText,
	StyledSubHeading
} from './styles/Login.styles';
import ForgotPasswordDialog from './ForgotPasswordDialog';

const pageContent = {
	en: {
		heading: 'Login',
		noAccountLabel: `Don't have an account?`,
		noAccountLabelHighlight: `Sign Up`,
		dividerText: 'or',
		resetPasswordSuccess: 'Changes saved. Password has been changed.',
		requestNewPasswordSuccess:
			'We already sent you a link to reset your password. Please check the email.'
	},
	id: {
		heading: 'Masuk',
		noAccountLabel: `Belum punya akun?`,
		noAccountLabelHighlight: `Daftar`,
		dividerText: 'atau',
		resetPasswordSuccess: 'Perubahan tersimpan. Kata sandi telah diubah.',
		requestNewPasswordSuccess:
			'Tautan telah dikirim untuk mengatur ulang kata sandi. Silakan periksa email.'
	}
};

const Login: React.FC = () => {
	const { language, parseRtkError } = useTranslate();
	const { showToast, renderToast } = useToast();

	const navigate = useNavigate();
	const location = useLocation();

	const goToNextPage = () => {
		type LocationState = {
			from?: {
				pathname: 'string';
			};
		};

		const from =
			(location.state as LocationState)?.from?.pathname || '/dashboard';
		navigate(from, { replace: true });
	};

	const handleLoginSuccess = useCallback(() => {
		goToNextPage();
	}, []);

	const handleLoginError = useCallback((error: RequestErrorReturn) => {
		showToast(parseRtkError(error));
	}, []);

	const [open, setOpen] = useState(false);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	const handleOpen = useCallback(() => {
		setOpen(true);
	}, []);

	const content = pageContent[language];

	const handleRequestNewPasswordSuccess = useCallback(() => {
		showToast(content.requestNewPasswordSuccess, 'success');
		handleClose();
	}, []);

	return (
		<LoginRoot>
			<OrnamentContainer>
				<StyledBrandIcon />

				<AccessAccountContainer>
					<StyledAccessAccount placeholder="false" src={AccessAccount} />
				</AccessAccountContainer>

				<OrnamentWaveContainer>
					<StyledOrnamentWave placeholder="false" src={OrnamentWave} />
				</OrnamentWaveContainer>
			</OrnamentContainer>

			<InputContainer>
				<InputWrapper>
					<StyledHeading component="h1" variant="h2">
						{content.heading}
					</StyledHeading>
					<StyledSubHeading
						component="a"
						href="https://taphomes.co.id/preapproval"
						variant="body1"
					>
						{content.noAccountLabel}{' '}
						<StyledSignupText component="span" variant="body1">
							{content.noAccountLabelHighlight}
						</StyledSignupText>
					</StyledSubHeading>

					<StyledGoogleLogin
						onLoginError={handleLoginError}
						onLoginSuccess={handleLoginSuccess}
					/>

					<DividerContainer>
						<StyledDivider />
						<DividerLabel component="a" variant="body1">
							{content.dividerText}
						</DividerLabel>
						<StyledDivider />
					</DividerContainer>

					<LoginForm
						onForgotButtonClicked={handleOpen}
						onLoginError={handleLoginError}
						onLoginSuccess={handleLoginSuccess}
					/>

					<ForgotPasswordDialog
						open={open}
						onClose={handleClose}
						onRequestNewPasswordError={handleRequestNewPasswordSuccess}
						onRequestNewPasswordSuccess={handleRequestNewPasswordSuccess}
					/>
				</InputWrapper>
			</InputContainer>
			{renderToast()}
		</LoginRoot>
	);
};

export default Login;
