import { configureStore } from '@reduxjs/toolkit';
import { authApi } from './services';
import { authReducer, settingsReducer } from './slices';

export const store = configureStore({
	reducer: {
		[authApi.reducerPath]: authApi.reducer,
		settings: settingsReducer,
		auth: authReducer
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(authApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
