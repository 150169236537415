import { ErrorMessage, ErrorMessageObject } from 'constants/error-message';
import { RequestErrorReturn } from 'store/types';

// TODO: use enum instead of plain object
const mapErrorCode = {
	UnregisteredOAuth: 'emailNotFound',
	IncorrectPassword: 'incorrectEmailPassword',
	UnregisteredEmail: 'incorrectEmailPassword',
	InvalidToken: 'invalidToken',
	InvalidOTP: 'invalidOTP',
	DuplicateEmail: 'duplicateEmail',
	DuplicatePhone: 'duplicatePhone',
	InputValidationError: 'invalidInput',
	InvalidCredentials: 'incorrectEmailPassword',
	LoginAttempt: 'loginAttempt',
	VerifyAttempt: 'verifyAttempt'
};

const parseRtkError = (
	error: RequestErrorReturn,
	errorMessage: ErrorMessageObject
) => {
	if (!('data' in error)) return errorMessage.somethingWentWrong;

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const { code } = error.data!;

	const errCode = code as keyof typeof mapErrorCode;

	return errorMessage[mapErrorCode[errCode] as ErrorMessage];
};

export default parseRtkError;
