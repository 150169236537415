import { Box, styled } from '@mui/material';

export const DashboardRoot = styled(Box)(({ theme }) => ({
	display: 'flex',
	minHeight: '100%',
	[theme.breakpoints.down('lg')]: {
		display: 'block'
	}
}));

export const DashboardContentContainer = styled(Box)({
	flexGrow: 1
});
