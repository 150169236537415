import { useTranslate } from 'hooks';
import React from 'react';
import { StyledTab, StyledTabs } from './styles/LanguageSwitcher.styles';

const LanguageSwitcher: React.FC = props => {
	const { language, changeLanguage } = useTranslate();

	const languageHandler = (
		event: React.SyntheticEvent,
		newValue: 'en' | 'id'
	) => {
		changeLanguage(newValue);
	};

	return (
		<StyledTabs value={language} onChange={languageHandler} {...props}>
			<StyledTab label="ID" value="id" />
			<StyledTab label="EN" value="en" />
		</StyledTabs>
	);
};

export default LanguageSwitcher;
