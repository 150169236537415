export interface MenuItem {
	url: string;
	name: string;
}

export interface MenuOptions {
	id: MenuItem[];
	en: MenuItem[];
}

export const menuOptions: MenuOptions = {
	id: [
		{
			url: '/dashboard/overview',
			name: 'Dasbor'
		},
		{
			url: '/dashboard/payments',
			name: 'Pembayaran'
		}
	],
	en: [
		{
			url: '/dashboard/overview',
			name: 'Dashboard'
		},
		{
			url: '/dashboard/payments',
			name: 'Payment'
		}
	]
};
