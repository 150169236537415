import { SvgIcon } from '@mui/material';
import React from 'react';

const GoogleIcon: React.FC = props => (
	<SvgIcon fill="none" viewBox="0 0 24 24" {...props}>
		<path
			d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
			fill="#FFC107"
		/>
		<path
			d="M3.15356 7.3455L6.43906 9.755C7.32806 7.554 9.48106 6 12.0006 6C13.5301 6 14.9216 6.577 15.9811 7.5195L18.8096 4.691C17.0236 3.0265 14.6346 2 12.0006 2C8.15956 2 4.82856 4.1685 3.15356 7.3455Z"
			fill="#FF3D00"
		/>
		<path
			d="M11.9999 21.9999C14.5829 21.9999 16.9299 21.0114 18.7044 19.4039L15.6094 16.7849C14.5717 17.574 13.3036 18.0009 11.9999 17.9999C9.39891 17.9999 7.19041 16.3414 6.35841 14.0269L3.09741 16.5394C4.75241 19.7779 8.11341 21.9999 11.9999 21.9999Z"
			fill="#4CAF50"
		/>
		<path
			d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
			fill="#1976D2"
		/>
	</SvgIcon>
);

export default GoogleIcon;
