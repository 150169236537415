import {
	Box,
	LinearProgress,
	linearProgressClasses,
	Link,
	styled,
	Typography
} from '@mui/material';

export const DepositCardRoot = styled(Box)(({ theme }) => ({
	borderRadius: 4,
	border: `1px solid ${theme.palette.grey[100]}`,
	padding: 16
}));

export interface BorderLinearProgressProps {
	progressColor?: string;
}

export const BorderLinearProgress = styled(LinearProgress, {
	shouldForwardProp: prop => prop !== 'progressColor'
})<BorderLinearProgressProps>(({ theme, progressColor }) => ({
	height: 12,
	borderRadius: 24,
	marginBottom: 16,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[300]
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: progressColor || theme.palette.primary.main
	}
}));

export const DepositCardContent = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center'
});

export const DepositTitle = styled(Typography)(({ theme }) => ({
	color: theme.palette.grey[600],
	[theme.breakpoints.down('sm')]: {
		marginBottom: 2
	}
})) as typeof Typography;

export const DetailLink = styled(Link)(({ theme }) => ({
	fontWeight: theme.typography.fontWeightBold,
	color: theme.palette.primary.main,
	marginLeft: 12
})) as typeof Link;
