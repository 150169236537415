import { GoogleButton } from 'components/Button';
import { GOOGLE_CLIENT_ID } from 'constants/api';
import { useTranslate } from 'hooks';
import React, { memo } from 'react';
import GoogleLogin, {
	GoogleLoginResponse,
	GoogleLoginResponseOffline
} from 'react-google-login';
import { useGoogleLoginMutation } from 'store/services';
import { RequestErrorReturn } from 'store/types';

const pageContent = {
	en: {
		googleButtonLabel: 'Login with Google'
	},
	id: {
		googleButtonLabel: 'Masuk dengan Google'
	}
};

interface LoginGoogleProps {
	onLoginSuccess: () => void;
	onLoginError: (error: RequestErrorReturn) => void;
}

const LoginGoogle: React.FC<LoginGoogleProps> = props => {
	const { onLoginSuccess, onLoginError, ...otherProps } = props;

	const { language } = useTranslate();

	const [googleLogin, { isLoading: isGLoginLoading }] =
		useGoogleLoginMutation();

	const onSignIn = async (
		_response: GoogleLoginResponse | GoogleLoginResponseOffline
	) => {
		try {
			const response = _response as GoogleLoginResponse;
			const idToken = response.tokenId;
			await googleLogin({ idToken });
			onLoginSuccess();
		} catch (error) {
			onLoginError(error as RequestErrorReturn);
		}
	};

	const content = pageContent[language];

	return (
		<GoogleLogin
			clientId={GOOGLE_CLIENT_ID}
			cookiePolicy="single_host_origin"
			render={renderProps => (
				<GoogleButton
					fullWidth
					loading={renderProps.disabled || isGLoginLoading}
					onClick={renderProps.onClick}
					{...otherProps}
				>
					{content.googleButtonLabel}
				</GoogleButton>
			)}
			onSuccess={onSignIn}
		/>
	);
};

export default memo(LoginGoogle);
