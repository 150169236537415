import { Dialog, styled } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-paper': {
		borderRadius: 16,
		margin: 16
	},
	'& .MuiDialog-paperWidthSm': {
		maxWidth: `388px !important`,
		[theme.breakpoints.down('sm')]: {
			maxWidth: `328px !important`
		}
	},
	'& .MuiDialog-paperFullWidth': {
		width: `calc(100% - 32px)`
	}
}));
