import React from 'react';

import { LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab';
import { StyledButton, StyledGoogleIcon } from './styles/GoogleButton.styles';

const GoogleButton: React.FC<
	Pick<MuiLoadingButtonProps, 'loading' | 'disabled' | 'fullWidth' | 'onClick'>
> = props => {
	const { loading, disabled, children, fullWidth, onClick, ...otherProps } =
		props;
	return (
		<StyledButton
			disabled={disabled}
			fullWidth={fullWidth}
			loading={loading}
			onClick={onClick}
			{...otherProps}
		>
			<StyledGoogleIcon />
			{children}
		</StyledButton>
	);
};

export default GoogleButton;
