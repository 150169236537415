import React from 'react';

import {
	Box,
	CssBaseline,
	StyledEngineProvider,
	ThemeProvider
} from '@mui/material';

import { Navigate, Route, Routes } from 'react-router-dom';
import { RequireAuth } from 'hoc';
import { Dashboard } from 'pages/Dashboard';
import { Login } from 'pages/Login';
import theme from 'styles/theme';
import { Overview } from 'pages/Overview';

const App: React.FC = () => (
	<StyledEngineProvider injectFirst>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Routes>
				<Route element={<Login />} path="login" />
				<Route
					element={
						// <RequireAuth>
						<Dashboard />
						// </RequireAuth>
					}
					path="dashboard"
				>
					<Route element={<Navigate to="overview" />} index />
					<Route element={<Overview />} path="/dashboard/overview" />
					<Route element={<Box>Payments</Box>} path="/dashboard/payments" />
				</Route>
				<Route element={<Navigate to="dashboard/overview" />} path="*" />
			</Routes>
		</ThemeProvider>
	</StyledEngineProvider>
);

export default App;
