import { ButtonBase, List } from '@mui/material';
import LanguageSwitcher from 'components/Switch/LanguageSwitcher';
import { MenuItem } from 'constants/menu-options';
import { useAppDispatch, useTranslate } from 'hooks';
import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { resetAuth } from 'store/slices';
import {
	BrandIconContainer,
	LanguageSwitcherContainer,
	StyledBrandIcon,
	StyledDrawer,
	StyledListItemButton,
	StyledListItemLogoutButton
} from './styles/SideNavigation.styles';

const componentContent = {
	id: {
		logout: 'Keluar'
	},
	en: {
		logout: 'Logout'
	}
};

interface SideNavigation {
	homeUrl: string;
	menuOptions: MenuItem[];
}

const SideNavigation: React.FC<SideNavigation> = props => {
	const { homeUrl, menuOptions } = props;

	const location = useLocation();

	const isRouteActive = (url: string) => !!matchPath(url, location.pathname);

	const dispatch = useAppDispatch();

	const handleLogout = () => {
		dispatch(resetAuth());
	};

	const { language } = useTranslate();
	const content = componentContent[language];

	return (
		<StyledDrawer variant="permanent">
			<List
				component="nav"
				disablePadding
				subheader={
					<BrandIconContainer>
						<ButtonBase component={Link} disableRipple to={homeUrl}>
							<StyledBrandIcon />
						</ButtonBase>
					</BrandIconContainer>
				}
			>
				{menuOptions.map(menu => (
					<StyledListItemButton
						component={Link}
						disableRipple
						key={menu.url}
						selected={isRouteActive(`${menu.url}/*`)}
						to={menu.url}
					>
						{menu.name}
					</StyledListItemButton>
				))}
				<StyledListItemLogoutButton
					aria-describedby="logout"
					onClick={handleLogout}
				>
					{content.logout}
				</StyledListItemLogoutButton>
			</List>

			<LanguageSwitcherContainer>
				<LanguageSwitcher />
			</LanguageSwitcherContainer>
		</StyledDrawer>
	);
};

export default SideNavigation;
