import { Grid } from '@mui/material';
import {
	PaymentReminderCard,
	RentalDepositCard,
	SecurityDepositCard
} from 'components/Card';
import BuybackOptionsInfoCard from 'components/Card/BuybackOptionsInfoCard';
import { DashboardContainer } from 'components/Utils';

import React from 'react';

const Overview = () => (
	<DashboardContainer>
		<Grid
			columnSpacing="20px"
			columns={{ xs: 4, sm: 8, lg: 12 }}
			container
			rowSpacing={{ xs: '24px', sm: '20px' }}
		>
			<Grid item lg={5} md={6} sm={4} xs={4}>
				<PaymentReminderCard />
				<SecurityDepositCard />
				<RentalDepositCard />
			</Grid>
			<Grid item lg={5} md={6} sm={4} xs={4}>
				<BuybackOptionsInfoCard />
			</Grid>
		</Grid>
	</DashboardContainer>
);

export default Overview;
