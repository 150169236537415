import { createSelector, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { authApi } from 'store/services';
import { UserAuth } from 'store/types';
import { RootState } from '../store';

interface AuthState {
	token?: string;
	user?: UserAuth;
}

const initialState: AuthState = {
	token: Cookies.get('token'),
	user: undefined
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		resetAuth(state) {
			Cookies.remove('token', { path: '/' });
			state.token = undefined;
			state.user = undefined;
		}
	},
	extraReducers: builder =>
		builder
			.addMatcher(
				authApi.endpoints.login.matchFulfilled,
				(state, { payload }) => {
					const { user, token: _token } = payload;

					const token = _token.split(' ')[1];

					Cookies.set('token', token, {
						expires: 1,
						sameSite: 'strict',
						secure: true
					});

					state.token = token;
					state.user = user;
				}
			)
			.addMatcher(
				authApi.endpoints.googleLogin.matchFulfilled,
				(state, { payload }) => {
					const { user, token: _token } = payload;

					const token = _token.split(' ')[1];

					Cookies.set('token', token, {
						expires: 1,
						sameSite: 'strict',
						secure: true
					});

					state.token = token;
					state.user = user;
				}
			)
			.addMatcher(
				authApi.endpoints.authUser.matchFulfilled,
				(state, { payload }) => {
					const { user } = payload;
					state.user = user;
				}
			)
			.addMatcher(authApi.endpoints.authUser.matchRejected, state => {
				Cookies.remove('token', { path: '/' });
				state.token = undefined;
				state.user = undefined;
			})
});

export const { resetAuth } = authSlice.actions;

const selectAuth = (state: RootState) => state.auth;

export const selectAuthenticatedUser = createSelector(
	selectAuth,
	auth => auth.user
);

export const selectAccessToken = createSelector(selectAuth, auth => auth.token);

export const authReducer = authSlice.reducer;
