import {
	alpha,
	Box,
	Divider,
	IconButton,
	styled,
	Typography
} from '@mui/material';
import CardOrnament from 'assets/card-ornament.svg';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const PaymentCardRoot = styled(Box)(({ theme }) => ({
	borderRadius: 8,
	padding: 16,
	backgroundImage: `url(${CardOrnament})`,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'right'
}));

export const Header = styled(Typography)(({ theme }) => ({
	color: theme.palette.common.white,
	[theme.breakpoints.down('sm')]: {
		marginBottom: 4
	}
})) as typeof Typography;

export const BilledAmount = styled(Typography)(({ theme }) => ({
	color: theme.palette.common.white,
	fontWeight: theme.typography.fontWeightBold,
	marginBottom: 20,
	[theme.breakpoints.down('sm')]: {
		marginBottom: 24
	}
})) as typeof Typography;

export const PaymentMethod = styled(Box)(({ theme }) => ({
	padding: '8px 16px',
	backgroundColor: theme.palette.green[400],
	borderRadius: 8,
	color: theme.palette.common.white,
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: 16,
	minWidth: 0,
	[theme.breakpoints.down('sm')]: {
		padding: '10px 16px'
	}
}));

export const PaymentMethodAccount = styled(Typography)(({ theme }) => ({
	opacity: 0.7,
	[theme.breakpoints.down('sm')]: {
		marginBottom: 4
	}
})) as typeof Typography;

export const PaymentMethodAccountNumber = styled(Typography)(({ theme }) => ({
	fontWeight: theme.typography.fontWeightBold
})) as typeof Typography;

export const CopyIcon = styled(ContentCopyIcon)(({ theme }) => ({
	height: 24,
	width: 24
}));

export const CopyIconButton = styled(IconButton)(({ theme }) => ({
	color: theme.palette.common.white,
	marginLeft: 12
}));
