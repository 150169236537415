import { Button } from 'components/Button';
import React from 'react';
import BuybackOptionsIllustration from 'assets/buyback-options-card.svg';
import {
	BuybackOptiosInfoCardRoot,
	Description,
	Illustration,
	IllustrationContainer,
	Title
} from './styles/BuybackOptionsInfoCard.styles';

const BuybackOptionsInfoCard = () => (
	<BuybackOptiosInfoCardRoot>
		<IllustrationContainer>
			<Illustration src={BuybackOptionsIllustration} />
		</IllustrationContainer>

		<Title component="h2" variant="h4">
			Yuk pahami opsi yang kamu punya!
		</Title>
		<Description>
			Tersedia beberapa opsi yang bisa digunakan untuk memanfaatkan ekuitas
			rumahmu. Konsultasikan opsi tersebut bersama kami!
		</Description>
		<Button color="primary" fullWidth variant="outlined">
			Selengkapnya
		</Button>
	</BuybackOptiosInfoCardRoot>
);

export default BuybackOptionsInfoCard;
