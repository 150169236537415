if (!process.env.REACT_APP_VAULT_API_BASE) {
	throw Error(
		'REACT_APP_VAULT_API_BASE is not defined in the environment variables.'
	);
}

if (!process.env.REACT_APP_GOOGLE_CLIENT_ID) {
	throw Error('GOOGLE_CLIENT_ID is not defined in the environment variables.');
}

export const VAULT_API_BASE = process.env.REACT_APP_VAULT_API_BASE;
export const VAULT_API_V2 = `${VAULT_API_BASE}/v2`;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
