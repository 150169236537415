import { TextFieldProps } from '@mui/material';
import React from 'react';
import { StyledTextField } from './styles/TextField.styles';

const TextField = React.forwardRef<unknown, TextFieldProps>((props, ref) => (
	<StyledTextField
		InputProps={{ disableUnderline: true }}
		inputRef={ref}
		variant="filled"
		{...props}
	/>
));

export default TextField;
