import { selectCurrentLanguage, setLanguage } from 'store/slices/settings';
import { validationMessage as _validationMessage } from 'constants/validation-message';
import { FieldError } from 'react-hook-form';
import {
	parseValidationMessage as _parseValidationMessage,
	parseRtkError as _parseRtkError
} from 'lib';
import { errorMessage as _errorMessage } from 'constants/error-message';
import { RequestErrorReturn } from 'store/types';
import useAppSelector from './use-app-selector';
import useAppDispatch from './use-app-dispatch';

const useTranslate = () => {
	const language = useAppSelector(selectCurrentLanguage);

	const validationMessage = _validationMessage[language];
	const errorMessage = _errorMessage[language];

	const parseValidationMessage = (error: FieldError | undefined) =>
		_parseValidationMessage(error, validationMessage);

	const parseRtkError = (error: RequestErrorReturn) =>
		_parseRtkError(error, errorMessage);

	const dispatch = useAppDispatch();

	const changeLanguage = (languageCode: 'id' | 'en') => {
		dispatch(setLanguage(languageCode));
	};

	return { language, parseValidationMessage, parseRtkError, changeLanguage };
};

export default useTranslate;
