import {
	AppBar,
	Box,
	ButtonBase,
	IconButton,
	styled,
	Toolbar
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { BrandIcon } from 'components/Icons';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
	backgroundColor: theme.palette.common.white
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
	paddingRight: 32,
	paddingLeft: 32,
	justifyContent: 'space-between',
	[theme.breakpoints.down('sm')]: {
		paddingRight: 16,
		paddingLeft: 16
	}
}));

export const LeftContainer = styled(Box)({
	display: 'flex'
});

export const MenuIconButton = styled(IconButton)(({ theme }) => ({
	width: 44,
	height: 44,
	[theme.breakpoints.down('sm')]: {
		width: 40,
		height: 40
	}
}));

export const StyledMenuIcon = styled(MenuIcon)(({ theme }) => ({
	height: 24,
	width: 24,
	color: theme.palette.primary.main,
	[theme.breakpoints.down('sm')]: {
		height: 20,
		width: 20
	}
}));

export const StyledBrandIconButton = styled(ButtonBase)({
	marginLeft: 8
}) as typeof ButtonBase;

export const StyledBrandIcon = styled(BrandIcon)(({ theme }) => ({
	width: 182,
	height: 32,
	color: theme.palette.primary.main,
	[theme.breakpoints.down('sm')]: {
		width: 114,
		height: 20
	}
}));

export const VoidBox = styled(Box)(({ theme }) => ({
	...theme.mixins.toolbar
}));
