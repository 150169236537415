import {
	ValidationMessage,
	ValidationMessageObject
} from 'constants/validation-message';
import { FieldError } from 'react-hook-form';

const parseValidationMessage = (
	error: FieldError | undefined,
	validation: ValidationMessageObject
) => (error?.message ? validation[error.message as ValidationMessage] : '');

export default parseValidationMessage;
