import { useTheme } from '@mui/material';
import React from 'react';
import { DepositCard } from '.';

const RentalDepositCard: React.FC = () => {
	const theme = useTheme();
	return (
		<DepositCard
			currentAmount={11000000}
			detailUrl="/deposit"
			initialAmount={15000000}
			progressColor={theme.palette.common.blue}
			title="Deposit Sewa"
		/>
	);
};

export default RentalDepositCard;
