import { Alert as MuiAlert, styled } from '@mui/material';

const Alert = styled(MuiAlert)(({ theme }) => ({
	'&.MuiAlert-root': {
		...theme.typography.body1,
		fontWeight: theme.typography.fontWeightMedium
	},
	'& .MuiAlert-message': {
		padding: '7px 0',
		[theme.breakpoints.down('sm')]: {
			padding: '8px 0'
		}
	}
}));

export default Alert;
