import { Typography } from '@mui/material';
import React from 'react';
import { StyledDialogTitleRoot } from './styles/DialogTitle.styles';

interface DialogTitleProps {
	children?: React.ReactNode;
}

const DialogTitle = (props: DialogTitleProps) => {
	const { children, ...other } = props;

	return (
		<StyledDialogTitleRoot {...other}>
			<Typography component="h2" variant="h3">
				{children}
			</Typography>
		</StyledDialogTitleRoot>
	);
};

export default DialogTitle;
