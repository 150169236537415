import { LoadingButton } from '@mui/lab';
import { darken, lighten, styled } from '@mui/material';

export interface StyledLoadingButtonProps {
	btnColor?: 'standard' | 'primary' | 'grey' | 'white' | 'primary-reversed';
	btnVariant?: 'contained' | 'outlined';
}

export const StyledLoadingButton = styled(LoadingButton, {
	shouldForwardProp: prop => prop !== 'btnColor' && prop !== 'btnVariant'
})<StyledLoadingButtonProps>(({ theme, btnColor, btnVariant }) => {
	const checkButtonType = (
		colorToCheck: StyledLoadingButtonProps['btnColor'],
		variantToCheck: StyledLoadingButtonProps['btnVariant']
	) => btnColor === colorToCheck && btnVariant === variantToCheck;

	const containedLoadingOrDisabled = {
		'&.MuiLoadingButton-loading': {
			color: `${theme.palette.grey[300]} !important`,
			'& .MuiCircularProgress-root': {
				color: theme.palette.common.white
			}
		},
		'&.Mui-disabled': {
			color: theme.palette.common.white,
			backgroundColor: theme.palette.grey[300]
		}
	};

	const outlinedLoadingOrDisabled = {
		'&.MuiLoadingButton-loading': {
			color: `${theme.palette.common.white} !important`,
			'& .MuiCircularProgress-root': {
				color: theme.palette.grey[300]
			}
		},
		'&.Mui-disabled': {
			color: theme.palette.grey[300],
			borderColor: theme.palette.grey[300]
		}
	};

	const primaryContained = {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		'&:hover': {
			backgroundColor: darken(theme.palette.primary.main, 0.4)
		},
		...containedLoadingOrDisabled
	};

	const primaryOutlined = {
		padding: `11px 19px`,
		border: `1px solid ${theme.palette.primary.main}`,
		...outlinedLoadingOrDisabled
	};

	const primaryReversedContained = {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: darken(theme.palette.common.white, 0.1)
		},
		...containedLoadingOrDisabled
	};

	return {
		padding: `12px 20px`,
		borderRadius: 8,
		'& .MuiCircularProgress-root': {
			height: `24px !important`,
			width: `24px !important`
		},
		...(checkButtonType('primary', 'contained') && primaryContained),
		...(checkButtonType('primary-reversed', 'contained') &&
			primaryReversedContained),
		...(checkButtonType('primary', 'outlined') && primaryOutlined)
	};
});
