import { DialogActions as MuiDialogActions, styled } from '@mui/material';

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
	padding: '16px 24px 24px',
	[theme.breakpoints.down('sm')]: {
		padding: 16
	},
	'& > *': {
		flex: '1 1 0'
	},
	'& > *:not(:last-child)': {
		marginRight: 16
	}
}));

export default DialogActions;
