import { Box } from '@mui/material';
import { Button } from 'components/Button';
import React from 'react';
import {
	BilledAmount,
	CopyIcon,
	CopyIconButton,
	Header,
	PaymentCardRoot,
	PaymentMethod,
	PaymentMethodAccount,
	PaymentMethodAccountNumber
} from './styles/PaymentReminderCard.styles';

const PaymentReminderCard = () => (
	<PaymentCardRoot>
		<Header component="h2" variant="body1">
			Sewa Periode September 2021
		</Header>
		<BilledAmount component="p" variant="h3">
			Rp 7.200.000
		</BilledAmount>
		<PaymentMethod>
			<Box>
				<PaymentMethodAccount component="h3" variant="body2">
					BCA Virtual Account
				</PaymentMethodAccount>
				<PaymentMethodAccountNumber>
					00998237758902835
				</PaymentMethodAccountNumber>
			</Box>
			<CopyIconButton>
				<CopyIcon />
			</CopyIconButton>
		</PaymentMethod>
		<Button color="primary-reversed" fullWidth variant="contained">
			Bayar Sekarang
		</Button>
	</PaymentCardRoot>
);

export default PaymentReminderCard;
