import { ButtonBase } from '@mui/material';
import { MenuItem } from 'constants/menu-options';
import { useAppDispatch, useTranslate } from 'hooks';
import React from 'react';
import { Link } from 'react-router-dom';
import { resetAuth } from 'store/slices';

import {
	BrandIconContainer,
	StyledBrandIcon,
	StyledCloseIcon,
	StyledCloseIconButton,
	StyledLanguageSwitcher,
	StyledList,
	StyledListItemButton,
	StyledListItemLogoutButton,
	StyledSwipeableDrawer
} from './styles/TopNavigationDrawer.styles';

const componentContent = {
	id: {
		logout: 'Keluar'
	},
	en: {
		logout: 'Logout'
	}
};

interface TopNavigationProps {
	homeUrl: string;
	menuOptions: MenuItem[];
	onClose: React.ReactEventHandler;
	onOpen: React.ReactEventHandler;
	open: boolean;
}

const TopNavigationDrawer: React.FC<TopNavigationProps> = props => {
	const { homeUrl, menuOptions, onClose, onOpen, open } = props;

	const dispatch = useAppDispatch();

	const handleLogout = () => {
		dispatch(resetAuth());
	};

	const { language } = useTranslate();
	const content = componentContent[language];

	return (
		<StyledSwipeableDrawer open={open} onClose={onClose} onOpen={onOpen}>
			{open && (
				<StyledCloseIconButton onClick={onClose}>
					<StyledCloseIcon />
				</StyledCloseIconButton>
			)}
			<StyledList
				component="nav"
				disablePadding
				subheader={
					<BrandIconContainer>
						<ButtonBase component={Link} disableRipple to={homeUrl}>
							<StyledBrandIcon />
						</ButtonBase>
					</BrandIconContainer>
				}
			>
				{menuOptions.map(menu => (
					<StyledListItemButton
						component={Link}
						disableRipple
						key={menu.url}
						to={menu.url}
						onClick={onClose}
					>
						{menu.name}
					</StyledListItemButton>
				))}
				<StyledListItemLogoutButton
					aria-describedby="logout"
					onClick={handleLogout}
				>
					{content.logout}
				</StyledListItemLogoutButton>
			</StyledList>
			<StyledLanguageSwitcher />
		</StyledSwipeableDrawer>
	);
};

export default TopNavigationDrawer;
