import { Box, styled, Typography } from '@mui/material';
import { SimpleImg } from 'react-simple-img';

export const BuybackOptiosInfoCardRoot = styled(Box)(({ theme }) => ({
	borderRadius: 4,
	border: `1px solid ${theme.palette.grey[100]}`,
	padding: 16
}));

export const Title = styled(Typography)({
	marginBottom: 8
}) as typeof Typography;

export const Description = styled(Box)({
	marginBottom: 24
}) as typeof Typography;

export const IllustrationContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	marginBottom: 24
});

export const Illustration = styled(SimpleImg)({
	maxHeight: '108px !important',
	maxWidth: '296px !important',
	height: '100% !important',
	width: '100%'
});
