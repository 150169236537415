import { Button } from 'components/Button';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from 'components/Dialog';
import { TextField } from 'components/Input';
import { isEmail, isRequiredString } from 'constants/yup-validation';
import React, { memo } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useForgotPasswordMutation } from 'store/services';
import { RequestErrorReturn } from 'store/types';
import { useTranslate } from 'hooks';
import DialogCloseButton from 'components/Dialog/DialogCloseButton';

const modalContent = {
	en: {
		emailLabel: 'Email',
		heading: `Forgot Your Password?`,
		message: `Please enter your email and we'll send a link to reset your password!`,
		sendButtonLabel: 'Send'
	},
	id: {
		emailLabel: 'Email',
		heading: `Lupa Kata Sandi?`,
		message: `Masukkan email dan terima link untuk mengganti kata sandimu!`,
		sendButtonLabel: 'Kirim'
	}
};

const schema = yup.object().shape({
	email: isEmail.concat(isRequiredString)
});

interface Inputs {
	email: string;
}

interface ForgotPasswordDialogProps {
	onRequestNewPasswordSuccess: () => void;
	onRequestNewPasswordError: (error: RequestErrorReturn) => void;
	onClose: () => void;
	open: boolean;
}

const ForgotPasswordDialog: React.FC<ForgotPasswordDialogProps> = props => {
	const {
		onClose,
		open,
		onRequestNewPasswordSuccess,
		onRequestNewPasswordError,
		...otherProps
	} = props;

	const { parseValidationMessage, language } = useTranslate();

	const handleClose = () => {
		onClose();
	};

	const { handleSubmit, control } = useForm<Inputs>({
		resolver: yupResolver(schema)
	});

	const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

	const onSubmit = handleSubmit(async data => {
		try {
			await forgotPassword({ email: data.email }).unwrap();
			onRequestNewPasswordSuccess();
		} catch (error) {
			onRequestNewPasswordError(error as RequestErrorReturn);
		}
	});

	const content = modalContent[language];

	return (
		<Dialog open={open} onClose={handleClose} {...otherProps}>
			<Box component="form" onSubmit={onSubmit}>
				<DialogCloseButton onClose={handleClose} />
				<DialogTitle>{content.heading}</DialogTitle>
				<DialogContent>
					<DialogContentText>{content.message}</DialogContentText>
					<Controller
						control={control}
						defaultValue=""
						name="email"
						render={({ field, fieldState: { error } }) => (
							<TextField
								error={!!error?.message}
								fullWidth
								helperText={parseValidationMessage(error)}
								id="email"
								label={content.emailLabel}
								type="email"
								{...field}
							/>
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						loading={isLoading}
						type="submit"
						variant="contained"
					>
						{content.sendButtonLabel}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default memo(ForgotPasswordDialog);
