import { alpha, styled } from '@mui/material';
import { GoogleIcon } from 'components/Icons';
import { Button } from '..';

export const StyledButton = styled(Button)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	border: `1px solid ${theme.palette.grey[400]}`,
	boxShadow: `${alpha(theme.palette.common.black, 0.18)} 0 1px 5px`,
	fontWeight: theme.typography.fontWeightRegular,
	color: theme.palette.common.black
}));

export const StyledGoogleIcon = styled(GoogleIcon)(({ theme }) => ({
	height: 24,
	width: 24,
	marginRight: 16,
	[theme.breakpoints.down('sm')]: {
		height: 20,
		width: 20,
		marginRight: 12
	}
}));
