import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
	DialogCloseButtonRoot,
	StyledIconButton
} from './styles/DialogCloseButton.styles';

interface DialogCloseButtonProps {
	onClose: () => void;
}

const DialogCloseButton: React.FC<DialogCloseButtonProps> = props => {
	const { onClose } = props;
	return (
		<DialogCloseButtonRoot>
			<StyledIconButton aria-label="close" onClick={onClose}>
				<CloseIcon />
			</StyledIconButton>
		</DialogCloseButtonRoot>
	);
};

export default DialogCloseButton;
