export const errorMessage = {
	en: {
		somethingWentWrong: 'Something went wrong. Please try again later.',
		emailNotFound: 'Could not find your email. Please sign up first.',
		incorrectEmailPassword: 'Your email and password combination is incorrect.',
		invalidToken: 'Your token is invalid or has expired.',
		invalidOTP: 'OTP is invalid or has expired.',
		duplicateEmail: 'Your email has been registered. Please login.',
		duplicatePhone: 'User already exists with that phone number.',
		invalidInput: 'Invalid Input.',
		loginAttempt: 'Too many login attempts. Please try again later.',
		verifyAttempt: 'Too many attempts. Please resend a new code.'
	},
	id: {
		somethingWentWrong: 'Terjadi kesalahan. Silakan coba lagi nanti.',
		emailNotFound:
			'Kami tidak menemukan emailmu. Silakan daftar terlebih dahulu.',
		incorrectEmailPassword: 'Kombinasi email dan kata sandimu salah.',
		invalidToken: 'Token tidak valid atau sudah kedaluwarsa.',
		invalidOTP: 'Kode OTP tidak sah atau kedaluwarsa.',
		duplicateEmail: 'Email Anda telah terdaftar. Silakan masuk.',
		duplicatePhone:
			'Nomor telepon sudah terpakai. Silakan gunakan nomor telepon yang lain.',
		invalidInput: 'Terjadi kesalahan.',
		loginAttempt: 'Terlalu banyak upaya masuk. Silakan coba lagi nanti.',
		verifyAttempt: 'Terlalu banyak percobaan. Harap kirim ulang kode baru.'
	}
};

export type ErrorMessageObject = typeof errorMessage['en'];
export type ErrorMessage = keyof typeof errorMessage['en'];
