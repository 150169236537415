import { useTheme } from '@mui/material';
import React from 'react';
import { DepositCard } from '.';

const SecurityDepositCard: React.FC = () => {
	const theme = useTheme();
	return (
		<DepositCard
			currentAmount={8000000}
			detailUrl="/deposit"
			initialAmount={15000000}
			progressColor={theme.palette.common.orange}
			title="Deposit Sekuritas"
		/>
	);
};

export default SecurityDepositCard;
