export const validationMessage = {
	en: {
		fieldNumberOnly: 'This field must contain only numbers.',
		fieldNumberOnlyShort: 'Numbers only.',
		fieldRequired: 'This field is required.',
		fieldRequiredShort: 'Required.',
		fieldNoCash: `This field couldn’t be Rp0,-.`,
		fieldZero: `This field couldn’t be 0.`,
		fieldOneLowercase: 'Must contain at least 1 lowercase character.',
		fieldOneUppercase: 'Must contain at least 1 uppercase character.',
		fieldOneNumeric: 'Must contain at least 1 numeric character.',
		fieldTenCharacters: 'Must be 10 characters or longer.',
		fieldEightCharacters: 'Must be 8 characters or longer.',
		fieldMaxSixteen: 'Must be 16 characters or shorter.',
		fieldMaxThirteen: 'Must be 13 characters or shorter.',
		invalidEmailFormat: 'Invalid email.',
		minFiveLettersShort: 'Min. 5 letters.',
		needFile: 'You need to provide a file.',
		phoneFormatInvalid: 'Invalid phone number.',
		invalidLength: 'Input length is invalid.',
		invalidOtp: 'Invalid OTP.',
		fileInvalidType: 'File type is invalid.',
		fileTooLarge: 'File size is too large',
		fieldMustFive: 'Must be 5 characters.',
		fieldMustFour: 'Must be 4 characters.',
		fieldMinDownpayment: 'Min. downpayment Rp5.000.000,-',
		fieldMinMonthlyPayment: 'Min. monthly payment Rp1.200.000,-',
		max500Chars: 'Max 500 characters.',
		fieldCashTakeout: 'Min. Rp200.000.000 and max. Rp3.000.000.000',
		fieldGreaterThanSixMonths: 'Must be more than 6 months',
		tooManyFiles: 'Too many files',
		invalidNIK: 'NIK is not valid.',
		invalidNPWP: 'NPWP is not valid.',
		invalidTrim: 'Cannot include leading or trailing spaces.',
		fieldMin0: 'Min. 0',
		fieldMin1: 'Min. 1',
		fieldMin10: 'Min. 10',
		fieldMax100: 'Max. 100',
		fieldMax1000: 'Max. 1000'
	},
	id: {
		fieldNumberOnly: 'Gunakan angka saja.',
		fieldNumberOnlyShort: 'Angka saja.',
		fieldRequired: 'Bagian ini diperlukan.',
		fieldRequiredShort: 'Wajib diisi.',
		fieldNoCash: `Tidak boleh Rp0,-.`,
		fieldZero: `Tidak boleh 0.`,
		fieldOneLowercase: 'Harus berisi setidaknya 1 karakter huruf kecil.',
		fieldOneUppercase: 'Harus berisi setidaknya 1 karakter huruf kapital.',
		fieldOneNumeric: 'Harus berisi setidaknya 1 karakter angka.',
		fieldTenCharacters: 'Harus 10 karakter atau lebih.',
		fieldEightCharacters: 'Harus 8 karakter atau lebih.',
		fieldMaxSixteen: 'Harus 16 karakter atau kurang.',
		fieldMaxThirteen: 'Harus 13 karakter atau kurang.',
		invalidEmailFormat: 'Email tidak sah.',
		minFiveLettersShort: 'Min. 5 huruf.',
		needFile: 'Anda perlu menyertakan berkas.',
		phoneFormatInvalid: 'Nomor telpon tidak sah.',
		invalidLength: 'Panjang input tidak sah.',
		invalidOtp: 'Kode OTP tidak sah.',
		fileInvalidType: 'Tipe berkas tidak valid.',
		fileTooLarge: 'Ukuran file terlalu besar',
		fieldMustFive: 'Harus berisi 5 karakter.',
		fieldMustFour: 'Harus berisi 4 karakter.',
		fieldMinDownpayment: 'Minimum downpayment adalah Rp5.000.000,-',
		fieldMinMonthlyPayment: 'Minimum pembayaran adalah Rp1.200.000,-',
		max500Chars: 'Maks. 500 karakter.',
		fieldCashTakeout: 'Min. Rp200.000.000 dan maks. Rp3.000.000.000',
		fieldGreaterThanSixMonths: 'Harus lebih dari 6 bulan',
		tooManyFiles: 'Berkas terlalu banyak',
		invalidNIK: 'NIK tidak sah.',
		invalidNPWP: 'NPWP tidak sah.',
		invalidTrim: 'Tidak boleh ada spasi di awal atau akhir.',
		fieldMin0: 'Min. 0',
		fieldMin1: 'Min. 1',
		fieldMin10: 'Min. 10',
		fieldMax100: 'Maks. 100',
		fieldMax1000: 'Maks. 1000'
	}
};

export type ValidationMessageObject = typeof validationMessage['en'];
export type ValidationMessage = keyof typeof validationMessage['en'];
